.display-widget {
    background-color: #272727;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    text-align: center;
    padding: 0px 60px 15px 60px;
    border-radius: 20px;
    min-width: 280px;
    margin-top: 40px;
    margin-bottom: 45px;
}

.display-widget-form {
    background-color: #272727;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    text-align: center;
    align-items: end;
    border-radius: 20px;
    max-width: 300px;
}

@media (max-width: 445px) {
    .display-widget {
        background-color: #272727;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        text-align: center;
        /* padding: 0px 10px 15px 10px; */
        border-radius: 20px;
        min-width: 300px;
        margin-top: 40px;
        margin-bottom: 45px;
    }
    
    .display-widget-form {
        background-color: #272727;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        text-align: center;
        align-items: end;
        border-radius: 20px;
        max-width: 250px;
    }

}



.display-widget-text {
    text-align: center;
    font-size: 18px;
    font-weight: 600;
}

.display-widget-textInput {
    text-align: center;
    font-size: 40px;
    font-weight: 600;
    background: none;
    border: none;
    color: white;
    width: 250px;
}

.max-button {
    margin-left: 10px;
    background-color: #1A1A1A;
    border: 1px solid #636363;
    color: white;
    padding: 2px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    font-weight: 600;
    border-radius: 50px;
    min-width: 50px;
    max-height: 30px;
}

.max-button:disabled {
    color: #636363;
}

.max-button:hover:not(:disabled) {
    background-color: white;
    color: black;
}