.home-section {
    background-color: #1A1A1A;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;

}

.header-text {
  text-align: center;
  
    font-size: 36px;
    font-weight: 600;
    height: 55px;
}

.header-sub-text {
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    max-width: 500px;
}

.card {
    margin-top: 100px;
    margin-bottom: 100px;
    display: flex;
    flex-direction: column;
    border: 1px solid #3F3E3E;
    border-radius: 10px;
    padding: 35px 100px 35px 100px;
    box-shadow: 0 4px 8px #3F3E3E;
    align-items: center;
    max-width: 684px;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5); 
    z-index: 1000; 
  }

.spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-left-color: #7983ff; 
    border-radius: 50%;
    width: 24px;
    height: 24px;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }