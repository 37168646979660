.step-with-divider {
    display: flex;
    flex-direction: column;
}

.dotted-line {
    border-left: 1px dotted white;
    height: 60px;
    margin: 0 20px;
}

.dotted-line.inactive {
    border-left: 1px dotted #636363;
}

.dotted-line.done {
    border-left: 1px dotted green;
}

.step {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 240px;
}

.step-text {
    color: green;
    font-size: 14px; /* Added 'px' */
    font-weight: 400;
}

.step-text.inactive {
    color: #636363;
}

.step-text.done {
    color: white;
}

.step-button {
    background-color:  green;
    border: none;
    color: white;
    padding: 10px;
    text-align: center;
    text-decoration: none;
    display: flex; /* Changed from 'inline-block' to 'flex' */
    align-items: center; /* Added to center align spinner and text */
    justify-content: center; /* Added to center align spinner and text */
    font-size: 16px;
    font-weight: 600;
    border-radius: 50px;
    min-width: 170px;
}

.step-button.inactive {
    background-color: #1A1A1A;
    color: #636363;
    border: 1px solid #636363;

}

.step-button.done {
    background-color: #1A1A1A;
    border: 1px solid #636363;
}

.step-button:hover:not(:disabled) {
    background-color: white;
    color: black;
}

.small-spinner {
    width: 1rem;
    height: 1rem;
    border: 2px solid #f3f3f3;
    border-top: 2px solid #007bff;
    border-radius: 50%;
    animation: spin 1s linear infinite;
    margin-right: 0.5rem; /* Space between spinner and text */
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
